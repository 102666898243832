import { createSelector } from "reselect";
import { initialState } from "./reducer";

const logoutDomain = state => state.logout || initialState;
const loginDomain = state => state.login || initialState;
const verifyDomain = state => state.verify || initialState;
const routerDomain = state => state.router || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Login
 */

const selectLogoutData = () =>
  createSelector(
    logoutDomain,
    subState => subState.toJS()
  );

const selectLoginData = () =>
  createSelector(
    loginDomain,
    subState => subState.toJS()
  );
const selectVerifyData = () =>
  createSelector(
    verifyDomain,
    subState => subState.toJS()
  );
const selectRouterData = () =>
  createSelector(
    routerDomain,
    subState => subState
  );

// const selectRouterData = () =>
// createSelector(
//   routerDomain(),
//   subState => subState.getIn("location")
// );

export {
  selectLogoutData,
  selectLoginData,
  selectVerifyData,
  selectRouterData
};
