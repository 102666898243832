/**
 * App
 * contain code that should be seen on all pages. (e.g. navigation bar)
 * This component is the skeleton around the actual pages, and should only
 *
 */

import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, Redirect } from "react-router-dom";
import { AppWorkspace, AppWrapper } from "./styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  defaultRedirection,
  isLoggedIn,
  isAdmin,
  isRetailer,
  isUser
} from "utils/auth";
import GlobalStyle from "global-styles";
import { connect } from "react-redux";
import { compose } from "redux";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";
import { triggerLogout } from "./actions";
import {
  selectLogoutData,
  selectLoginData,
  selectVerifyData,
  selectRouterData
} from "./selectors";
import { createStructuredSelector } from "reselect";
import saga from "./sagas";
import reducer from "./reducer";
import Login from "screens/Auth/Login/Loadable";
import Verify from "screens/Auth/Verify/Loadable";
import MyNavbar from "components/MyNavbar";
import Consumer from "screens/Consumer/Loadable";
import Order from "screens/Order/Loadable";
import Queue from "screens/Queue/Loadable";
import Product from "screens/Product/Loadable";
import Print from "screens/Print/Loadable";
import Home from "screens/Home/Loadable";

import Dashboard from "screens/Dashboard/Loadable";
import User from "screens/Dashboard/User/Loadable";
import Statics from "screens/Dashboard/Statics/Loadable";
import Buy from "screens/Buy/Loadable";
import OrderFlow from "screens/Buy/OrderFlow/Loadable";
import ReactGA from "react-ga";
import "../utils/i18n"; // YOUR_OWN_TRACKING_ID
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import QuickOrder from "screens/QuickOrder/Loadable";
const TRACKING_ID = "UA-130237504-1";

function AppRoot(props) {
  ReactGA.initialize(TRACKING_ID);
  const firebaseConfig = {
    apiKey: "AIzaSyCOWXy7dblq8hz-6qXRMZiMFX7Eva47XmU",
    authDomain: "bakery-670d5.firebaseapp.com",
    projectId: "bakery-670d5",
    storageBucket: "bakery-670d5.appspot.com",
    messagingSenderId: "711498623478",
    appId: "1:711498623478:web:892bf2d65d5488a47affd7",
    measurementId: "G-YHS4S4SKR5"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const currentPathName = props.routerData.location.pathname;
  return (
    <AppWrapper>
      <Helmet titleTemplate="%s - Radha Bakery" defaultTitle="Radha Bakery">
        <meta name="description" content="A Radha Bakery application" />
      </Helmet>
      {/* {!isLoggedIn() && <Redirect to="/" />} */}
      {currentPathName && (
        <MyNavbar
          currentPathName={currentPathName}
          onLogout={props.triggerLogout}
          isLoggedIn={isLoggedIn()}
          isAdmin={isAdmin()}
          isRetailer={isRetailer()}
          isUser={isUser()}
        />
      )}
      <AppWorkspace>
        <Switch>
          <Route path="/user" component={User} />
          <Route path="/statics" component={Statics} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/consumer" component={Consumer} />
          <Route path="/order" component={Order} />
          <Route path="/queue" component={Queue} />
          <Route path="/product" component={Product} />
          <Route path="/print" component={Print} />
          <Route path="/login" component={Login} />
          <Route path="/verify" component={Verify} />
          <Route path="/about" component={Home} />
          <Route path="/buy/order" component={OrderFlow} />
          <Route path="/buy" component={Buy} />
          <Route path="/quick-order" component={QuickOrder} />
          <Route path="/" component={Home} />

          <Route exact path="" component={() => <Fragment></Fragment>} />

          <Route path="" component={() => <div>Not Found</div>} />
        </Switch>
        {defaultRedirection("root")}
        <ToastContainer />
      </AppWorkspace>

      <GlobalStyle />
    </AppWrapper>
  );
}

const mapStateToProps = createStructuredSelector({
  logoutData: selectLogoutData(),
  loginData: selectLoginData(),
  verifyData: selectVerifyData(),
  routerData: selectRouterData()
});

function mapDispatchToProps(dispatch) {
  return {
    triggerLogout: data => {
      dispatch(triggerLogout(data));
    }
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: "logout", reducer });
const withSaga = injectSaga({ key: "logout", saga });

export default compose(withReducer, withSaga, withConnect)(AppRoot);
