import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-image: linear-gradient(to left, rgb(248 215 181), rgb(252 222 192));
  background-image: linear-gradient(to left, rgb(112 47 159), rgb(252 222 192));
}

  body.fontLoaded {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  #app {
    min-height: 100%;
    min-width: 100%;
  }

  p, label {
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
    margin-bottom: 2px;
    margin-top: 2px;
  }
  a:hover{
    text-decoration: none;
  }
  .container {
    
  }
  .justifyEven {
    justify-content: space-between;
  }
  .pullRight {
    justify-content: flex-end;
  }  
  .buttonWrapper {
    display: flex;
    justify-items: center;
    justify-content: space-around;
    padding-top: 10px;
  }
  .marginBottom{
    margin-bottom: 20px
  }
  .marginTop{
    margin-top: 20px
  }
  .marginRight{
    margin-right: 10px
  }
`;
export default GlobalStyle;
