export const API_BASE = "https://radhabakery.com";
//export const API_BASE = "http://localhost:1234";
const API_MOUNT_POINT = "/api/v2";
//export const API_BASE_URL = `https://radhabakery.com${API_MOUNT_POINT}`;

// export const API_BASE = "https://rbapp.ranvirgorai.com";
// // const API_APP_BASE = 'https://rbapp.ranvirgorai.com/';
// const API_MOUNT_POINT = "/api/v2";
export const API_BASE_URL = `${API_BASE}${API_MOUNT_POINT}`;

export const AUTH_URL = `${API_BASE_URL}/auth`;
export const ORDER_URL = `${API_BASE_URL}/order`;
export const END_POINT_PRODUCT = `${API_BASE_URL}/product`;
export const END_POINT_USERS_URL = `${API_BASE_URL}/user`;
export const END_POINT_CONSUMER = `${API_BASE_URL}/consumer`;
export const END_POINT_CONTAINER = `${API_BASE_URL}/container`;
export const END_POINT_QUEUE = `${API_BASE_URL}/queue`;
export const END_POINT_UPLOAD = `${API_BASE_URL}/upload`;
export const END_POINT_REGISTER = `${AUTH_URL}/register`;
export const END_POINT_STATICS = `${API_BASE_URL}/statics`;
