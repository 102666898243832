import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "assets/r4b-main.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { CgGitFork } from "react-icons/cg";
import { ImBlog } from "react-icons/im";
import {
  AiFillStar,
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
  AiOutlineUserSwitch,
  AiOutlineMessage,
  AiOutlineUserAdd,
  AiOutlineLogout,
  AiOutlineLogin,
  AiOutlinePrinter,
  AiFillDashboard,
  AiOutlineAccountBook,
  AiFillAmazonSquare
} from "react-icons/ai";

import { CgFileDocument } from "react-icons/cg";

import { useTranslation } from "react-i18next";
import { NavDropdown } from "react-bootstrap";

const navigationConfig = [
  {
    to: "/dashboard",
    text: "Dashboard",
    isLoggedIn: true,
    isAdmin: true,
    isRetailer: false,
    isUser: false,
    icon: <AiFillDashboard style={{ marginBottom: "2px" }} />
  },

  {
    to: "/quick-order",
    text: "Quick Order",
    isLoggedIn: true,
    isAdmin: false,
    isRetailer: true,
    isUser: false,
    icon: <AiOutlineUser style={{ marginBottom: "2px" }} />
  },
  {
    to: "/print",
    text: "Print",
    isLoggedIn: true,
    isAdmin: false,
    isRetailer: true,
    isUser: false,
    icon: <AiOutlinePrinter style={{ marginBottom: "2px" }} />
  },
  {
    to: "/consumer",
    text: "Consumer",
    isLoggedIn: true,
    isAdmin: false,
    isRetailer: true,
    isUser: false,
    icon: <AiOutlineUser style={{ marginBottom: "2px" }} />
  },

  {
    to: "/order",
    text: "Order",
    isLoggedIn: true,
    isAdmin: false,
    isRetailer: true,
    isUser: false,
    icon: <AiOutlineUser style={{ marginBottom: "2px" }} />
  },
  {
    to: "/queue",
    text: "Queue",
    isLoggedIn: true,
    isAdmin: false,
    isRetailer: true,
    isUser: false,
    icon: <AiOutlineAccountBook style={{ marginBottom: "2px" }} />
  },
  {
    to: "/product",
    text: "Product",
    isLoggedIn: true,
    isAdmin: false,
    isRetailer: true,
    isUser: false,
    icon: <AiFillAmazonSquare style={{ marginBottom: "2px" }} />
  },

  {
    to: "/buy",
    text: "Buy",
    isLoggedIn: true,
    isAdmin: false,
    isRetailer: false,
    isUser: true
  },
  {
    to: "/buy/order",
    text: "Place Order",
    isLoggedIn: true,
    isAdmin: false,
    isRetailer: false,
    isUser: true
  }
];

function NavBar({
  currentPathName,
  onLogout,
  isLoggedIn,
  isAdmin,
  isRetailer,
  isUser
}) {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const { t, i18n } = useTranslation();

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  function handleLanguage(language) {
    i18n.changeLanguage(language);
  }

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          <img src={logo} className="img-fluid logo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "2px" }} /> {t("Home")}
              </Nav.Link>
            </Nav.Item>

            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineMessage style={{ marginBottom: "2px" }} /> {t('About')}
              </Nav.Link>
            </Nav.Item> */}
            {/* 
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/members"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUserSwitch
                  style={{ marginBottom: "2px" }}
                />{" "}
                {t('Members')}
              </Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/resume"
                onClick={() => updateExpanded(false)}
              >
                <CgFileDocument style={{ marginBottom: "2px" }} /> Resume
              </Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
              <Nav.Link
                href="https://blogs.goraicommunity.com/"
                target="_blank"
                rel="noreferrer"
              >
                <ImBlog style={{ marginBottom: "2px", marginRight: "4px" }} />{t('Blog')}
              </Nav.Link>
            </Nav.Item> */}
            {navigationConfig
              .filter(
                item =>
                  item.isLoggedIn === isLoggedIn &&
                  item.isAdmin === isAdmin &&
                  item.isUser === isUser &&
                  item.isRetailer === isRetailer
              )
              .map(item => (
                <Nav.Item key={item.text}>
                  <Nav.Link
                    as={Link}
                    to={item.to}
                    onClick={() => updateExpanded(false)}
                  >
                    {item.icon} {t(item.text)}
                  </Nav.Link>
                </Nav.Item>
              ))}

            {isLoggedIn ? (
              <Nav.Item>
                <Nav.Link as={Link} to="/login" onClick={onLogout}>
                  <AiOutlineLogout style={{ marginBottom: "2px" }} />{" "}
                  {t("Logout")}
                </Nav.Link>
              </Nav.Item>
            ) : (
              <Nav.Item style={{ opacity: 0 }}>
                <Nav.Link
                  as={Link}
                  to="/login"
                  onClick={() => updateExpanded(false)}
                >
                  <AiOutlineLogin style={{ marginBottom: "2px" }} />{" "}
                  {t("Login")}
                </Nav.Link>
              </Nav.Item>
            )}
            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/register"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUserAdd
                  style={{ marginBottom: "2px" }}
                />{" "}
                {t('Register')}
              </Nav.Link>
            </Nav.Item> */}

            {/* <NavDropdown title={t("Language")} id="basic-nav-dropdown">
              <NavDropdown.Item
                href="#action/3.1"
                onClick={() => handleLanguage("en")}
              >
                English
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#action/3.2"
                onClick={() => handleLanguage("hi")}
              >
                हिन्दी
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#action/3.2"
                onClick={() => handleLanguage("bn")}
              >
                বাংলা
              </NavDropdown.Item>
            </NavDropdown> */}

            {/* <Nav.Item className="fork-btn">
              <Button
                href="https://github.com/ranvirgorai"
                target="_blank"
                className="fork-btn-inner"
              >
                <CgGitFork style={{ fontSize: "1.2em" }} />{" "}
                <AiFillStar style={{ fontSize: "1.1em" }} />
              </Button>
            </Nav.Item> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
